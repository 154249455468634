.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@import url('https://fonts.googleapis.com/css2?family=Anta&family=Josefin+Sans:ital,wght@1,100;1,500&family=Kode+Mono:wght@400..700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');


.titleDefault1{
  font-family: "Playfair Display", sans-serif;
  font-optical-sizing: auto;
  font-weight: weight;
  font-style: normal;
  text-align: center;
  font-size: 45px;
  color: #e9e9e1;
}
.titleDefault1 span{
  font-weight: bold;
  color: #11110b;
  font-size: 50px;
  padding: 5px;
}

.titleDefault2{
  font-family: "Playfair Display", sans-serif;
  font-optical-sizing: auto;
  font-weight: weight;
  font-style: normal;
  text-align: center;
  font-size: 25px;
  color: #11110b;
}

.textDefault{
  font-family: "Playfair Display", sans-serif;
  font-optical-sizing: auto;
  font-weight: weight;
  font-style: normal;
  font-size: 20px;
  color: #e9e9e1;
}

.container-info-lote{
  display: grid;
  grid-template-areas: "p p p" "a a a" "l l e";
  gap: 25px;
  margin: 20px;
}

.info-assoc{
  grid-area: a;
  margin: auto;
  max-width: 80%;
  text-align: justify;
}

.info-produto{
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-image: url('./assets/img/Default_brown_and_green_rainforest_flat_minimal_illustration_1_21b45312-ccf3-486b-859f-62e4ec2263d9_1\ 1.png');
  background-color: red;
  grid-area: p;
  border-radius: 25px;
}
.info-produto h1 {
  margin-left: 20px;
}
.info-produto img {
  width: 150px;
  border-radius: 20px;
  margin-left: 15px;
}

.info-locais{
  display: flex;
  flex-direction: column;
  background-color: #d4d4d3;
  background-position: center;
  background-size: cover;
  grid-area: l;
  border-radius: 25px;
}
.info-locais img{
  width: 100%;
  height: 450px;
  object-fit: cover;
  border-radius: 25px;
}

.info-extrativistas{
  display: flex;
  flex-direction: column;
  grid-area: e;
  border-radius: 25px;
}
.info-extrativistas img{
  align-self: center;
  height: 350px;
  border-radius: 25px;
}